"use strict";
import bootstrapper from "lib/loader";
require(`../style/${env.INSTANCE}/main.less`);

let api_url = env.API_URL;
let sentry_dsn = env.SENTRY;

bootstrapper((resolve) => (
    require(['components/Router'], (...imported) => {
        resolve(imported);
    })
), api_url, sentry_dsn);
