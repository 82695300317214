'use strict';

const messages = {
    // validator
    'generic.validator.required': 'Hodnota je povinná',
    'generic.validator.checkbox.required': 'Je povinné zaškrtnúť',
    'generic.validator.url': 'Hodnota musí byť validná URL',
    'generic.validator.email': 'Prosím zadajte platný email',
    'generic.validator.url_list_1': 'Hodnota musí byť validná URL',
    'generic.validator.url_list_2': 'Jedna z URL adries nie je validná',
    'generic.validator.generic': 'Nesprávna hodnota',
    'generic.validator.password_no_match': 'Heslá sa nezhodujú',
    'generic.validator.password_short': 'Heslo musí obsahovať minimálne 6 znakov',
    // errors
    'generic.error.title': 'Niečo sa pokazilo',
    'generic.error.text': 'Prosím obnovte stránku.',
    'generic.error.badcsrf.title': 'Platnosť aplikácie vypršala',
    'generic.error.badcsrf.text': 'Prosím obnovte stránku.',
    'generic.error.unavailable.title': 'Nepodarilo sa pripojiť k API serveru',
    'generic.error.unavailable.text': 'Prosím obnovte stránku.',
    // values
    'generic.values.kilometers': 'km',
    'generic.values.czech_crowns': '€',
    // forms
    'generic.form.error': 'Niečo sa pokazilo',
    'generic.form.error.badvalues': 'Skontrolujte prosím zadané údaje',
    'generic.form.email': 'E-mail',
    'generic.form.password': 'Heslo',
    'generic.form.password_again': 'Heslo znova',
    'generic.form.password_old': 'Staré heslo',
    'generic.form.text': 'Vaša správa',
    'generic.form.submit': 'Odoslať',
    // others
    'generic.api_error': '<strong>Nepodarilo sa pripojiť k API serveru a preto nie je prihlásenie dostupné.</strong><br>Skúste stránku obnoviť alebo navštíviť neskôr. Naši technici plne pracujú na vyriešení problému. Ďakujeme za pochopenie.',
    // eu cookie bar
    'cookiebar.text': 'Táto stránka používa cookies a ďalšie technológie umožňujúce jej funkčnosť a na analýzu návštevnosti. Keď na tejto stránke na niečo kliknete alebo prejdete, vyjadríte tým svoj súhlas s používaním cookies.',
    'cookiebar.button': 'Súhlasím',
    // header navigation
    'header.login': 'Prihlásenie',
    'header.register': 'Registrácia',
    'header.logout': 'Odhlásiť sa',
    'header.settings': 'Nastavenie',
    'header.howitworks': 'Ako to funguje?',
    'header.cards': 'Aktívne karty',
    'header.faq': 'FAQ',
    'header.contact': 'Kontaktný formulár',
    'header.offers': 'Moje ponuky',
    'header.menu': 'Menu',
    'header.back': 'Späť',
    'header.programpages': 'Stránky programu',
    'header.back.offers': 'Moje ponuky',
    'header.savings': 'Moje úspory',
    'header.savings.total': 'Celkom ušetrené',
    'header.savings.credited': 'Bude pripísané',
    // footer
    'footer.copy': 'Dateio',
    // not found #404
    'notfound.title': '#404 Stránka nenájdená',
    'notfound.text': 'Je nám ľúto, ale požadovaná stránka nebola nájdená.',
    // not allowed #401
    'notallowed.title': '#401 Nepovolený prístup',
    'notallowed.text': "Pre zobrazenie tejto stránky sa najprv musíte prihlásiť.",
    // login
    'login.link_to': 'Prihlásiť sa',
    'login.title': 'Prihlásenie',
    'login.subtitle': 'Pre prihlásenie prosím vyplňte svoje užívateľské meno a heslo',
    'login.form.email': 'Prihlasovacie meno (e-mail)',
    'login.form.remember': 'Zostať prihlásený(á)',
    'login.form.submit': 'Prihlásiť sa',
    'login.form.error.badvalues': 'Nesprávne zadané meno alebo heslo',
    'login.form.error.badcredentials': 'Nesprávne zadané meno alebo heslo',
    // register
    'register.title': 'Registrácia',
    'register.subtitle': 'Pre registráciu prosím vyplňte všetky údaje',
    'register.form.firstName': 'Meno',
    'register.form.lastName': 'Priezvisko',
    'register.form.email': 'E-mail',
    'register.form.cardNumberFragment': 'Posledných 6 číslic čísla Vašej karty',
    'register.form.terms': 'Súhlasím s obchodnými podmienkami aplikácie a so spracovaním osobných údajov.',
    'register.form.submit': 'Registrovať sa',
    'register.form.error.badvalues': 'Skontrolujte prosím zadané údaje',
    'register.form.error.terms': 'Musíte súhlasiť s obchodnými podmienkami',
    'register.success.title': 'Ďakujeme za registráciu',
    'register.success.text': 'Prosím skontrolujte zadanú emailovú schránku, čoskoro by Vám mal prísť odkaz pre nastavenie hesla k Vášmu účtu.',
    'register.success.confirmation': 'Rozumiem',
    'register.link_to': 'Ešte nemáte účet? Registrujte sa tu.',
    // set password
    'setpassword.title': 'Nastavte si heslo',
    'setpassword.text': 'Zmena hesla k účtu:',
    'setpassword.wrong_hash': 'Tento odkaz nie je platný. Skontrolujte prosím odkaz a skúste to znova.',
    'setpassword.form.submit': 'Zmeniť heslo',
    'setpassword.form.error.badvalues': 'Neplatné heslo alebo odkaz',
    'setpassword.registration.title': 'Aktivácia zliav na karte',
    'setpassword.registration.username': 'Užívateľské meno',
    'setpassword.registration.text': 'Pre prihlasovanie do zľavového portálu bude použitý e-mail, ktorý ste uviedli(a) pri zakladaní svojho bankového účtu:',
    'setpassword.registration.form.submit': 'Aktivovať',
    'setpassword.registration.about.title': 'O aplikácii',
    'setpassword.registration.about.text': 'Jedná sa o zľavový program, s ktorým môžete šetriť až desiatky eur mesačne u viac ako 150 partnerov.',
    'setpassword.registration.about.simple.title': 'Jednoduché čerpanie zliav',
    'setpassword.registration.about.simple.text': 'Žiadne vouchery ani nosenie desiatok vernostných kartičiek. K získaniu zľavy stačí len aktivovať ponuku na stránkach <a href="https://www.u-setrite.sk" target="_blank"> www.u-setrite.sk </a> alebo v mobilnej aplikácii.',
    'setpassword.registration.about.card.title': 'Zľava je zviazaná s Vašou platobnou kartou',
    'setpassword.registration.about.card.text': 'Nakúpte u obchodníka a zaplaťte svojou platobnou kartou UniCredit Bank. Zľavu pri nákupe nemusíte nijak preukazovať ani si o ňu žiadať.',
    'setpassword.registration.about.payback.title': 'Získajte späť peniaze',
    'setpassword.registration.about.payback.text': 'Späť získavate peniaze, žiadne body ani kupóny na ďalší nákup.',
    // forgotten password
    'lostpassword.link_to': 'Zabudli ste heslo?',
    'lostpassword.title': 'Zabudnuté heslo',
    'lostpassword.subtitle': 'Pre obnovenie hesla vyplňte e-mail nižšie',
    'lostpassword.form.submit': 'Obnoviť heslo',
    'lostpassword.form.error.badvalues': 'Zadaný e-mail neexistuje',
    'lostpassword.success.title': 'Skontrolujte si email',
    'lostpassword.success.text': 'Prosím skontrolujte zadanú emailovú schránku, čoskoro by Vám mal prísť odkaz pre nastavenie nového hesla k Vášmu účtu.',
    'lostpassword.success.confirmation': 'Rozumiem',
    // logout modal
    'logoutmodal.title': 'Odhlásenie',
    'logoutmodal.text': 'Naozaj sa chcete odhlásiť?',
    'logoutmodal.deny': 'Nie',
    'logoutmodal.confirmation': 'Áno',
    // offer deactivate modal
    'offerdeactivatemodal.title': 'Naozaj chcete ponuku schovať?',
    'offerdeactivatemodal.text': 'Schovanú ponuku už vám neukážeme v sekcii Moje ponuky, ale vždy ju môžete vyhľadať v kategórii Všetko.',
    'offerdeactivatemodal.deny': 'Nie',
    'offerdeactivatemodal.confirmation': 'Áno',
    // popup modal
    'popup.title': 'Upozornenie',
    'popup.titleconditions': 'K ponuke sa vzťahujú nasledujúce podmienky',
    'popup.confirmation': 'Rozumiem',
    // mobile modal
    'mobilemodal.title': 'Prečo mať aplikáciu v mobile?',
    'mobilemodal.p1': 'Nájdite zľavy vo svojej blízkosti, napríklad v nákupnom centre alebo večer v meste.',
    'mobilemodal.p2': 'Aktivujte ich priamo počas nakupovania, bez starostí.',
    // help modal
    'helpmodal.title': 'Čo teraz?',
    'helpmodal.p1': 'Teraz už stačí len nakúpiť a zaplatiť svoju kartou.',
    'helpmodal.p2': 'Pri platení si o zľavu nemusíte hovoriť, bude Vám priznaná automaticky.',
    'helpmodal.p3': 'Usporené peniaze dostanete späť do 30 dní od nákupu.',
    // how it works modal
    'howitworksmodal.title': 'Ako ušetríte?',
    // sections - features
    'sections.features.title': 'Aplikácia je ...',
    'sections.features.title1': 'Jednoduchá',
    'sections.features.p1': 'Zľavy sú prepojené priamo s Vašou platobnou kartou. Žiadne ďalšie kartičky, vouchery alebo zľavové kódy.',
    'sections.features.title2': 'Diskrétne',
    'sections.features.p2': 'Pri platení o zľavu nemusíte žiadať. Zľava Vám bude automaticky pripísaná na účet spojený s platobnou kartou.',
    'sections.features.title3': 'Osobné',
    'sections.features.p3': 'Ponuky sú vybrané Vám na mieru na základe Vašich predchádzajúcich nákupov.',
    // sections - howitworks
    'sections.howitworks.title': 'Ako to funguje?',
    'sections.howitworks.title1': 'Vyberte si zľavu',
    'sections.howitworks.p1': 'Na počítači alebo v mobilnej aplikácii si z ponuky ušitej Vám na mieru vyberte zľavu a jedným klikom ju aktivujte.',
    'sections.howitworks.title2': 'Zaplaťte kartou',
    'sections.howitworks.p2': 'Nakúpte u obchodníka a zaplaťte svojou platobnou kartou. O zľave pri tom nemusíte nikomu hovoriť!',
    'sections.howitworks.title3': 'Získajte peniaze späť',
    'sections.howitworks.p3': 'Späť získavate peniaze, žiadne body ani kupóny. Usporené peniaze dostanete priamo na svoj bankový účet.',
    // howitworks
    'howitworks.title': 'Ako to funguje?',
    'howitworks.title1': 'Aktivujte',
    'howitworks.p1': 'Z ponuky si vyberte zľavu a jedným kliknutím ju aktivujte. Pokiaľ nie je stanovené inak, uplatňuje sa zľava vždy na celý nákup vrátane už zľavneného tovaru. V detaile ponuky nájdete prípadné obmedzenia využiteľnosti ponuky týkajúce sa konkrétnej pobočky, času, počtu využití, minimálnej útraty alebo maximálnej výšky zľavy.',
    'howitworks.title2': 'Zaplaťte kartou',
    'howitworks.p2': 'Nakúpte u vybraného obchodníka a útratu zaplaťte svojou debetnou kartou. Pri platení nebude o Vašom nároku na zľavu nikto vedieť a nikomu o zľave nemusíte hovoriť. Využívanie našich zliav je pohodlné a diskrétne.',
    'howitworks.title3': 'Získajte peniaze späť',
    'howitworks.p3': 'Získavate peniaze, žiadne body alebo kupóny. Zľavy Vám budú pripísané na bankový účet dvakrát mesačne, najneskôr do 30 dní od nákupu. Prehľad všetkých vyplatených zliav aj zliav čakajúcich na vyplatenie nájdete v sekcii',
    'howitworks.p3link': 'Moje Úspory',
    // sections - mobileapp
    'sections.mobileapp.title': 'Aplikácia na internete alebo v mobile',
    'sections.mobileapp.p1': 'V pokoji a pohodlí domova si ponuky prehliadajte na svojom počítači či tablete',
    'sections.mobileapp.p2': 'Na nákupoch, v autobuse alebo v práci využite mobilnú aplikáciu. Zľavy tak máte vždy po ruke a nemusíte si ich pamätať. S aplikáciou môžete zľavy aktivovať až v priebehu nakupovania a naviac s ňou nájdete zľavy, ktoré prebiehajú najbližšie Vám, napríklad v neďalekej reštaurácii alebo nákupnom centre.',
    'sections.mobileapp.screenshot': 'Fotka',
    // sections - partners
    'sections.partners.title': 'Partneri programu',
    'sections.partners.p1': 'Aktuálny zoznam partnerov a zľavových ponúk nájdete po prihlásení.',
    'sections.partners.p2': 'Medzi partnerov, s ktorými dlhodobo spolupracujeme, patria:',
    // offers
    'offers.empty': 'Je nám ľúto, ale v tejto kategórii pre Vás zatiaľ nemáme žiadne ponuky.',
    'offers.loadmore': 'Načítať ďalšie',
    'offers.failed': 'Nepodarilo sa načítať ponuky',
    'offers.search': 'Hľadanie',
    'offers.search.empty': 'Je nám ľúto, ale pre váš dotaz neboli nájdené žiadne výsledky.',
    'offers.search.form.error.short': 'Hľadanie musí obsahovať minimálne 3 znaky',
    'offers.title.assigned': 'Moje Ponuky',
    'offers.title.others': 'Ďalšie Ponuky',
    'offers.time.until.short': 'Do',
    'offers.time.until.long': 'Platí do',
    'offers.time.remaining': 'Zostáva',
    'offers.activate': 'Chcem ponuku',
    'offers.activated': 'Aktívna',
    'offers.activated.tick': ' ',
    'offers.hide': 'Schovať',
    'offers.detail.about': 'O ponuke',
    'offers.detail.visiteshop': 'Prejsť do e-shopu',
    'offers.detail.rules': 'Pravidlá',
    'offers.detail.where': 'Kde čerpať',
    'offers.detail.eshop': 'E-shop',
    'offers.detail.notinterested': 'O túto zľavu nemám záujem',
    'offers.detail.empty.title': 'Detail ponuky nenájdený',
    'offers.detail.empty.text': 'Je nám ľúto, ale požadovaná ponuka nebola nájdená.',
    'offers.sortby.default': 'Doporučené',
    'offers.sortby.name': 'A - Z',
    'offers.sortby.activation': 'Aktívne',
    'offers.sortby.new': 'Najnovšie',
    'offers.sortby.end_soon': 'Čoskoro končiace',
    'offers.new': 'Novinka',
    'offers.extended' : 'Predĺžená',
    'offers.homepage.1.text' : 'Pohonné hmoty',
    'offers.homepage.1.discount' : '2',
    'offers.homepage.2.text' : 'Reštaurácie',
    'offers.homepage.2.discount' : '30',
    'offers.homepage.3.text' : 'Lekárne',
    'offers.homepage.3.discount' : '4',
    'offers.homepage.4.text' : 'Móda',
    'offers.homepage.4.discount' : '10',
    'offers.homepage.upto' : 'Až',
    // preview
    'offers.preview.header' : 'Ponuky dostupné po prihlásení',
    // unsubscribe
    'unsubscribe.title': 'Odhlásenie e-mailových notifikácií',
    'unsubscribe.wrong_hash' : 'Tento odkaz nie je platný. Skontrolujte prosím odkaz a skúste to znova.',
    'unsubscribe.new_offers' : 'Prajete si odhlásiť odber upozornení o nových ponukách?',
    'unsubscribe.expiring_offers' : 'Prajete si odhlásiť odber upozornení o končiacich ponukách?',
    'unsubscribe.new_usage' : 'Prajete si odhlásiť odber upozornení  o zaznamenaných  využitiach?',
    'unsubscribe.newsletter' : 'Prajete si odhlásiť odber upozornení s tipmi a zaujímavosťami?',
    'unsubscribe.button' : 'Odhlásiť odber',
    'unsubscribe.new_offers_done': 'Odhlásenie odberu upozornení o nových ponukách prebehlo v poriadku.',
    'unsubscribe.expiring_offers_done': 'Odhlásenie odberu upozornení o končiacich ponukách prebehlo v poriadku.',
    'unsubscribe.new_usage_done': 'Odhlásenie odberu upozornení o zaznamenaných využitiach prebehlo v poriadku.',
    'unsubscribe.newsletter_done': 'Odhlásenie odberu upozornení s tipmi a zaujímavosťami prebehlo v poriadku.',
    'unsubscribe.popup_header': 'Notifikácie odhlásené',
    // savings
    'savings.title': 'Moje úspory',
    'savings.empty': 'Zatiaľ nemáte žiadne využité ponuky',
    'savings.subheading.total': 'Celkom ušetrené',
    'savings.items.name': 'Názov ponuky',
    'savings.items.date': 'Dátum',
    'savings.items.amount': 'Zaplatené',
    'savings.items.discount': 'Ušetrené',
    'savings.unpaid.title': 'Bude pripísané na bežný účet',
    'savings.paid.title': 'Už vyplatené',
    'savings.debit': 'Debetná karta',
    'savings.credit': 'Kreditná karta',
    // contact
    'contact.title': 'Kontakt',
    'contact.success': 'Správa odoslaná. Ďakujeme za spätnú väzbu',
    'contact.text1': 'Máte otázky k fungovaniu služby a nenašli ste odpoveď v',
    'contact.textLink': 'často kladených otázkach',
    'contact.text2': 'Máte návrh na zlepšenie alebo ste narazili na problém? Budeme radi, ak nám napíšete.',
    'contact.submit': 'Odoslať',
    'contact.wrong_hash': 'Tento odkaz nie je platný. Skontrolujte prosím odkaz a skúste to znova.',
    'contact.empty': 'Napíšte nám čo Vás trápi a stlačte tlačidlo Odoslať.',
    // faq
    'faq.title': 'Často kladené otázky',
    'faq.empty': 'Túto sekciu pre Vás pripravujeme.',
    //settings
    'settings.title': 'Nastavenia',
    'settings.popup.title': 'Zmena nastavení',
    'settings.popup.text': 'Vaše nastavenie bolo úspešne uložené.',
    'settings.popup.confirmation': 'Rozumiem',
    'settings.popup.delete.title': 'Naozaj chcete zmazať účet?',
    'settings.popup.delete.text': 'Zmazanie účtu Vám znemožní využitie aktivovaných zliav.',
    'settings.popup.delete.confirmation': 'Áno',
    'settings.popup.delete.deny': 'Nie',
    'settings.cards.title': 'Vaše platobné karty zapojené do programu',
    'settings.cards.empty': 'Vaše platobné karty tu zobrazíme do 48 hodín.',
    'settings.cards.missing.text1': 'Pokiaľ tu nejaká Vaša platobná karta chýba,',
    'settings.cards.missing.contact': 'napíšte nám',
    'settings.cards.missing.text2': 'a my už s tým niečo urobíme.',
    'settings.cards.card.visa': 'Visa',
    'settings.cards.card.mastercard': 'Mastercard',
    'settings.cards.card.debit': 'Debetná karta',
    'settings.cards.card.credit': 'Kreditná karta',
    'settings.password.title': 'Zmena hesla',
    'settings.password.submit': 'Zmeniť heslo',
    'settings.password.show': 'Zobraziť heslo',
    'settings.password.hide': 'Skryť heslo',
    'settings.checkbox.new': 's novými ponukami',
    'settings.checkbox.expiring': 's končiacimi aktivovanými ponukami',
    'settings.checkbox.tips': 's tipmi a zaujímavosťami',
    'settings.checkbox.usages': 's novo pripísanou úsporou',
    'settings.checkboxes.title': 'Zasielanie e-mailov',
    'settings.checkboxes.submit': 'Uložiť',
    'settings.user.delete.title': 'Zmazať účet',
    'settings.user.delete.button': 'Zmazať účet',
    // mobile advertisement text
    'advertisement.mobile.text': 'Stiahnuť mobilnú aplikáciu',
};

export default messages;
