'use strict';
// Babel polyfill must be there, as well as Intl
import "@babel/polyfill";
import injectTapEventPlugin from 'react-tap-event-plugin';
injectTapEventPlugin();
// Polyfill for promises
require('es6-promise').polyfill();
// Intl polyfill for Safari and IE
import "intl";
import "intl/locale-data/jsonp/en.js";
import { Integrations } from "@sentry/tracing";
import {addLocaleData, IntlProvider} from 'react-intl';
import englishLocaleData from 'react-intl/locale-data/en';
import czechLocaleData from 'react-intl/locale-data/cs';
import slovakLocaleData from 'react-intl/locale-data/sk';
import romanianLocaleData from 'react-intl/locale-data/ro';
import Moment from 'moment';

addLocaleData([
    ...englishLocaleData,
    ...czechLocaleData,
    ...slovakLocaleData,
    ...romanianLocaleData
]);

/**
 * A function that loads react and others asynchronously, therefore being able to make the loading experience nicer.
 * The require has to be done step by step (and that's why it's so verbose), otherwise, static analysis and code
 * splitting will fail.
 */
export default function bootstrapper(componentLoader, apiRoot=null, sentryDsn=null) {
    let React = null;
    let ReactDOM = null;
    let Bootstrapper = null;
    let Redux = null;
    let Component = null;
    let ReduxProvider = null;
    let ApplicationStateStore = null;
    let Raven = null;

    let bootstrap_app = null;

    // Total number of components to be loaded
    let componentNumber = 5;

    //Initialize the application, one piece at a time
    return new Promise((resolve, reject) => {
        require(['react', 'react-dom', 'components/Bootstrapper'], (...imported) => {
            resolve(imported);
        });
    }).then((modules) => {
        React = modules[0];
        ReactDOM = modules[1];
        Bootstrapper = modules[2].default;
        let totalComponents = componentNumber;
        if (apiRoot !== null) {
            totalComponents++;
        }
        if (sentryDsn !== null) {
            totalComponents++;
        }

        // Mount the bootstrapper react component
        bootstrap_app = ReactDOM.render(
            <Bootstrapper totalComponents={totalComponents}/>, document.getElementById('react-container')
        );

        // Load fluxxor
        return new Promise((resolve, reject) => {
            require(['redux'], (...imported) => {
                resolve(imported);
            })
        })
    }).then((modules) => {
        Redux = modules[0];
        bootstrap_app.setState({loaded: bootstrap_app.state.loaded + 1});

        return new Promise((resolve, reject) => {
            componentLoader(resolve);
        })
    }).then((modules) => {
        Component = modules[0].default;
        bootstrap_app.setState({loaded: bootstrap_app.state.loaded + 1});

        return new Promise((resolve, reject) => {
            require(['reducers'], (...imported) => {
                resolve(imported);
            })
        })
    }).then((modules) => {
        ApplicationStateStore = modules[0].default;
        bootstrap_app.setState({loaded: bootstrap_app.state.loaded + 1});

        return new Promise((resolve, reject) => {
            require(['react-redux', 'redux-thunk'], (...imported) => {
                resolve(imported);
            })
        })
    }).then((modules) => {
        if (sentryDsn !== null) {
            // LOAD: Raven (Sentry)

            return new Promise((resolve, reject) => {
                require(['@sentry/react'], (...imported) => {
                    modules.push(imported[0]);
                    resolve(modules);
                });
            });
        }else{
            return modules;
        }
    }).then((modules) => {
        if (sentryDsn !== null) {
            // INIT: Raven (Sentry);
            let Sentry = modules[2];
            Sentry.init({
                dsn: sentryDsn,

                // To set your release version
                release: env.SENTRY_RELEASE,
                integrations: [new Integrations.BrowserTracing()],
                environment: env.SENTRY_ENVIRONMENT,

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 0.33,
            });

            bootstrap_app.setState({loaded: bootstrap_app.state.loaded + 1});
        }
        return modules;
    }).then((modules) => {
        // load & init google analytics
        if (env.GOOGLE_ANALYTICS_ID) {
            let ReactGA = require('react-ga');
            ReactGA.initialize(env.GOOGLE_ANALYTICS_ID);
            ReactGA.set({ anonymizeIp: true });
        }
        bootstrap_app.setState({loaded: bootstrap_app.state.loaded + 1});
        return modules
    }).then((modules) => {
        if (apiRoot !== null) {
            // Load the application router and start the app
            return new Promise((resolve, reject) => {
                require(['actions/app'], (...imported) => {
                    modules.push(imported[0]);
                    resolve(modules);
                })
            })
        }  else {
            return modules;
        }
    }).then((modules) => {
        ReduxProvider = modules[0].Provider;

        let thunk = modules[1].default;

        bootstrap_app.setState({loaded: bootstrap_app.state.loaded + 1});

        let store = Redux.createStore(
            ApplicationStateStore, Redux.compose(
                Redux.applyMiddleware(thunk),
                // Enable redux dev tools, if installed in chrome
                window.devToolsExtension ? window.devToolsExtension() : f => f
            )
        );

        if (apiRoot !== null) {
            let appActionsModule = modules[modules.length-1];

            store.dispatch(appActionsModule.initializeApi(apiRoot)).then(() => {
                bootstrap_app.setState({loaded: bootstrap_app.state.loaded + 1});
                // boot app even with api init error
                finishInit(React, ReactDOM, ReduxProvider, Component, store);

                // if (store.getState().api.get('state') === 'error') {
                //     bootstrap_app.setState({error: true});
                // } else {
                //     finishInit(React, ReactDOM, ReduxProvider, Component, store);
                // }
            })
        } else {
            finishInit(React, ReactDOM, ReduxProvider, Component, store);
        }
    });
}

/**
 * React must be passed in, even though it looks unused, otherwise webpack gets confused and this does not work at all.
 *
 * @param React
 * @param ReactDOM
 * @param ReduxProvider
 * @param Component
 * @param store
 */
function finishInit(React, ReactDOM, ReduxProvider, Component, store) {
    setTimeout(() => {
        ReactDOM.unmountComponentAtNode(document.getElementById('react-container'));

        let locale = store.getState().app.get('language');
        let localeMessages = null;

        // check if language in supported languages
        if (env.LANGUAGES.includes(locale)) {
            localeMessages = require(`locale/${env.INSTANCE}/${locale}`).default;
            Moment.locale(locale);
        } else {
            // default language
            localeMessages = require(`locale/${env.INSTANCE}/${env.LANGUAGE}`).default;
            Moment.locale(env.LANGUAGE);
        }

        const render = (Component) => {
            ReactDOM.render(
                <IntlProvider locale={locale} messages={localeMessages}>
                    <ReduxProvider store={store}>
                        <Component />
                    </ReduxProvider>
                </IntlProvider>,
                document.getElementById('react-container')
            )
        };

        render(Component);

        if (module.hot) {
            module.hot.accept([], () => {
                console.info("accept?");
                render(Component);
            });
        }
    }, 400);
}
