'use strict';
// Strings
import sharedMessages from 'locale/shared/sk';

const messages = {
    // offers preview
    'offers.preview.text' : 'Stačí vám platobná karta od Poštovej banky a prístup do služby Internet banking a získate prístup aj k týmto ponukám.',
    'offers.preview.register' : 'Zaregistrovať sa',
    'offers.preview.header' : 'Zaregistrujte sa bezplatne',

    // header
    'header.section.intro': 'O programe',
    'header.section.howitworks': 'Ako to funguje',
    'header.section.application': 'Partneri',
    'header.section.faq': 'Najčastejšie otázky',
    'header.login': 'Prihlásiť sa',
    'header.username': 'Prihlásený majiteľ karty: ',

    // home page sections
    'sections.video.play': 'Pozrite si, ako ľahko funguje program Peniaze\u00A0s5',
    'sections.partners.p1': 'Aktuálny zoznam partnerov a zliav nájdete po prihlásení.',
    'sections.partners.p2': 'Medzi partnerov, s ktorými dlhodobo spolupracujeme, patria:',
    'sections.mobileapp.title': 'Peniaze s5 na internete alebo v\u00A0mobile',
    'sections.mobileapp.p1': 'Ponuku programu Peniaze s5 si môžete prezerať, nech ste kdekoľvek. Pokojne v pohodlí domova na svojom počítači, tablete alebo v mobile.',
    'sections.mobileapp.p2': 'Zľavy môžete aktivovať aj cestou z práce, v MHD alebo počas nakupovania. Navyše, ak používate mobilnú aplikáciu Poštovej banky, tá vás upozorní na najbližšie obchodné miesta, kde si ich aktuálne môžete uplatniť.',
    'sections.howitworks.title': 'Ako to funguje?',
    'sections.howitworks.title1': 'Vyberte si zľavy',
    'sections.howitworks.p1': 'Na stránke www.peniazes5.sk alebo v mobilnej aplikácii Poštovej banky si vyberte zľavy, resp. ponuky, ktoré chcete využiť. Potom ich jednoduchým kliknutím aktivujte a môžete nakupovať.',
    'sections.howitworks.title2': 'Zaplaťte kartou',
    'sections.howitworks.p2': 'Pri nákupe na základe aktivovanej ponuky zaplaťte platobnou kartou Poštovej banky. Uhrádzate plnú sumu tovaru alebo služby.',
    'sections.howitworks.title3': 'A získate peniaze späť',
    'sections.howitworks.p3': 'Ušetrené peniaze vo výške vybranej zľavy sa vám na základe aktivovanej ponuky a realizovaného nákupu pripíšu späť na účet, ku ktorému je karta vydaná.',
    'sections.features.title': 'S programom Peniaze\u00A0s5 získate zľavy',
    'sections.features.title1': 'Pohodlne',
    'sections.features.title2': 'Bez starostí',
    'sections.features.title3': 'Na mieru',
    'sections.intro.title1': 'Zľavy na každom kroku',
    'sections.features.p1': 'Zľavu si ľahko aktivujete prostredníctvom stránky www.peniazes5.sk alebo mobilnej aplikácie a zaplatíte svojou platobnou kartou Poštovej banky.',
    'sections.features.p2': 'Zľava sa automaticky pripíše na váš účet. O zľavu nemusíte dodatočne žiadať ani sa preukazovať v obchode.',
    'sections.features.p3': 'Na základe vašich predchádzajúcich nákupov vám ponúkneme zľavy na taký tovar a služby, ktoré skutočne využijete.',
    'sections.whom.title1': 'Kto môže získať Peniaze\u00A0s5?',
    'sections.whom.title2': 'Ako si aktivujem Peniaze\u00A0s5?',
    'sections.whom.p1': 'Všetci držitelia platobnej karty k účtu od Poštovej banky s prístupom do služby Internet banking.',
    'sections.whom.p2': 'Ak máte kartu Poštovej banky vedenú na svoje meno viazanú k Užitočnému účtu a prístup do služby Internet banking, stačí už len udeliť súhlas s Podmienkami programu odmeňovania.',
    'sections.whom.p3': 'O tento súhlas vás požiadame, keď sa prvý raz prihlásite na stránke www.peniazes5.sk alebo cez mobilnú aplikáciu svojimi prihlasovacími údajmi do Internet bankingu, alebo keď v Internet bankingu kliknete na tlačidlo Peniaze s5.',
    'sections.intro.bubbles.1.title': 'ZľavaDňa',
    'sections.intro.bubbles.1.discount': '5',
    'sections.intro.bubbles.2.title': '101 Drogerie',
    'sections.intro.bubbles.2.discount': '3',
    'sections.intro.bubbles.3.title': 'Booking',
    'sections.intro.bubbles.3.discount': '4',
    'sections.intro.bubbles.4.title': 'Panta Rhei',
    'sections.intro.bubbles.4.discount': '10',
    'sections.offerspreview.title': 'Ponuky vybraných predajcov',
    'sections.intro.allbutton' : 'Pozrieť všetky ponuky',

    // footer
    'footer.section.howitworks': 'Ako to funguje?',
    'footer.section.faq': 'Najčastejšie otázky',
    'footer.section.conditions': 'Podmienky programu',
    'footer.section.right.1': 'Klientske centrum',
    'footer.section.right.2': '0850 00 6500 alebo *6500',
    'footer.section.right.3': 'každý deň od 8.00 hod. do 19.00 hod.,',
    'footer.section.right.4': 'počas víkendu od 9.00 hod. do 17.00 hod.',
    'footer.section.right.5': 'mimo štátnych sviatkov',

    // offers
    'offers.search': 'Čo potrebujete nájsť?',
    'offers.activate': 'Chcem ponuku',
    'offers.activated': 'Aktivovaná',
    'offers.activated.tick': '✓\u00A0\u00A0',
    'offers.detail.notinterested': 'Nechcem ponuku',

    // how it works
    'howitworks.title': 'Ako to funguje?',
    'howitworks.title1': 'Vyberiete si zľavu',
    'howitworks.p1': 'Zo širokej ponuky zliav šitých vám na mieru si vyberiete jednu alebo pokojne i viacero naraz. Vami vybrané zľavy si v počítači alebo mobilnej aplikácii aktivujete jedným kliknutím.',
    'howitworks.title2': 'Zaplatíte kartou',
    'howitworks.p2': 'Zaplaťte platobnou kartou Poštovej banky u obchodníka a zľava je vaša! Pričom o získaní zľavy pri platení viete iba vy.',
    'howitworks.title3': 'A peniaze sú vaše',
    'howitworks.p3': 'Ušetrené peniaze za uplatnenie budú pripísané na účet, ku ktorému je karta vydaná najneskôr do jedného mesiaca.',

    // savings
    'savings.subheading.total': 'Ušetrené celkom',
    'savings.items.name': 'Uplatnená zľava',
    'savings.items.date': 'Dátum nákupu',
    'savings.items.amount': 'Celková suma',
    'savings.items.discount': 'Získaná zľava',
    'savings.unpaid.title': 'Bude pripísané na účet',
    'savings.paid.title': 'Všetky vyplatené zľavy',

    //faq
    'faq.header.1': 'Čo je program Peniaze s5?',
    'faq.text.1': 'Peniaze s5 je program, ktorý ako prvá na Slovensku prináša Poštová banka. U vybraných obchodníkov s ním získate peniaze späť pri platbe kartou.',
    'faq.header.2': 'Ako si aktivujem Peniaze s5?',
    'faq.text.2': 'Program Peniaze s5 môže využívať každý držiteľ karty k Užitočnému účtu, ktorý má k účtu vydanú platobnú kartu a zriadenú službu Internet banking. Podmienkou je udeliť súhlas s Podmienkami programu odmeňovania Peniaze s5. O tento súhlas vás požiadame, keď sa prvý raz prihlásite na stránke www.peniazes5.sk svojimi prihlasovacími údajmi do Internet bankingu, alebo keď v Internet bankingu kliknete na tlačidlo Peniaze s5.',
    'faq.header.3': 'Ako môžem získať Peniaze s5?',
    'faq.text.3': 'Vyberte si zľavy, ktoré vás zaujímajú a aktivujte ich. Ak neviete nájsť zľavu, ktorá by vás aktuálne oslovila, pohľadajte v ďalších kategóriách, ktoré sme pre vás pripravili. Po aktivovaní vami vybraných zliav stačí, ak pri platení za ponuku použijete platobnú kartu Poštovej banky. O uplatnenie zliav a pripísanie peňazí na účet nie je potrebné žiadať ani sa ničím preukazovať. Pri zaplatení sa z účtu, ku ktorému je platobná karta vydaná, odčíta plná suma za nákup a následne peniaze vrátime späť na účet. Viac informácií nájdete v Podmienkach vernostného programu Peniaze s5. Vo svojom internet bankingu, vo výpise z účtu alebo na internetovej stránke www.peniazes5.sk v časti Moje úspory si môžete skontrolovať, koľko ste za nákupy ušetrili.',
    'faq.header.4': 'Aký je poplatok za využívanie služby Peniaze s5?',
    'faq.text.4': 'Žiadny. Peniaze s5 získavate úplne zadarmo. Vaše zľavy vám vrátime späť bez akýchkoľvek poplatkov. Stačí mať platobnú kartu od Poštovej banky na svoje meno, prístup do Internet bankingu, aktivovať vami vybrané ponuky a zaplatiť kartou u obchodníka.',
    'faq.header.5': 'V akom predstihu je potrebné si zľavu aktivovať?',
    'faq.text.5': 'Zľavu si môžete aktivovať aj bezprostredne pred nákupom, napríklad pri čakaní v rade na zaplatenie.',
    'faq.header.6': 'Čo sa stane, ak ponuku označím, že ju nechcem?',
    'faq.text.6': 'Ponuka sa bude zobrazovať len v prehľade všetkých (nie vašich) ponúk.',
    'faq.header.7': 'Čo sa stane, ak ponuku po aktivácii nevyužijem?',
    'faq.text.7': 'Vôbec nič. Vaše nákupy sú len na vás.',
    'faq.header.8': 'Čím sa musím preukázať, ak chcem zľavu?',
    'faq.text.8': 'Využívanie zliav je úplne diskrétne. Nepotrebujete sa preukazovať žiadnymi kartami, poukážkami či vytlačenými zľavovými kupónmi. Stačí zaplatiť platobnou kartou Poštovej banky tak, ako máte vo zvyku.',
    'faq.header.9': 'Kam mi budú pripísané ušetrené peniaze?',
    'faq.text.9': 'Priamo na účet, ku ktorému je vydaná platobná karta, ktorou platíte vami vybrané zľavové ponuky.',
    'faq.header.10': 'Koľko môžem získať cez Peniaze s5?',
    'faq.text.10': 'Pokiaľ nie je bližšie špecifikované, tak platí, že neexistuje žiaden maximálny limit na vyplácanie úspor. Ušetrené peniaze sú však vyplácané minimálne od sumy 0,005 €, pričom v takomto prípade sa vyplatia vo výške 0,01 €. Nižšie úspory sa nevyplácajú.',
    'faq.header.11': 'Prečo nevidím ušetrené peniaze zo svojho nákupu hneď po zaplatení? Kedy uvidím informácie o vrátení peňazí?',
    'faq.text.11': 'Od vášho nákupu až po spracovanie môže uplynúť niekoľko dní. To znamená, že deň zaúčtovania transakcie sa nemusí rovnať dňu vášho nákupu. Práve preto sa môže stať, že vaše ušetrené peniaze uvidíte až po niekoľkých dňoch od nákupu. Prehľad o pripísaných platbách nájdete na internetovej stránke www.peniazes5.sk alebo vo výpise z účtu. Ušetrené peniaze späť na účet budú pripísané, na účet ku ktorému je karta vydaná dva krát mesačne, v polovici a ku koncu mesiaca. Bližšie informácie sú uvedené v Podmienkach vernostného programu Peniaze s5.',
    'faq.header.12': 'V prípade, že ste splnili všetky podmienky v rámci obchodných podmienok a napriek tomu neboli pripísané peniaze späť na účet, kontaktujte nás, prosím, na čísle Klientskeho centra 0850 00 6500 alebo *6500. Radi vám pomôžeme.',
    'faq.text.12': 'Späť na účet, ku ktorému je karta vydaná posielame peniaze dvakrát mesačne (V polovici a na konci mesiaca), najneskôr Vám prídu vždy do mesiaca od zaúčtovania platby. Koľko sa má pripísať na účet, ku ktorému je karta vydaná a koľko ste ušetrili si môžeme skontrolovať v sekcii Moje úspory.',
    'faq.header.13': 'Prečo mám vo svojich úsporách za nákupy uvedenú mínusovú položku?',
    'faq.text.13': 'Mínusovú položku môžete mať v prípade, že zakúpený tovar, za ktorý vám boli pripísané ušetrené peniaze, ste vrátili alebo reklamovali.',
    'faq.header.14': 'Ako si zmením prihlasovacie údaje?',
    'faq.text.14': 'Prihlasovacie údaje sú identické s prihlasovacími údajmi do Internet bankingu. V prípade, ak si chcete zmeniť heslo pre program Peniaze s5, môžete tak urobiť vo svojom Internet bankingu, pričom nové heslo bude zároveň platné aj pre váš Internet banking.',
    'faq.header.15': 'Môžem opakovane, resp. koľko krát môžem využiť aktivovanú ponuku?',
    'faq.text.15': 'Štandardne si ponuku aktivujete len raz a využívať ju môžete opakovane koľko krát chcete, až pokiaľ nevyprší jej platnosť (ak nie je pri konkrétnej ponuke špecifikované inak).',

    // settings
    'settings.checkbox.agree': 'Súhlasím',
    'settings.checkboxes.title': 'Chcem dostávať emailom:',
    'settings.checkbox.new': 'nové ponuky',
    'settings.checkbox.expiring': 'končiace ponuky',
    'settings.checkbox.usages': 'pripísanie úspor',
    'settings.checkbox.tips': 'tipy a zaujímavosti',
    'settings.user.delete.title': 'Chcem využívať zľavy a súhlasím s Podmienkami programu odmeňovania Peniaze s5 a so spracúvaním osobných údajov v rámci programu Peniaze s5',
    'settings.user.delete.button': 'Uložiť',
    'settings.cards.title': 'Karty, ktorými môžem využívať zľavy',
    'settings.popup.text': 'Vaše nastavenia boli úspešne uložené. Vaša požiadavka bude spracovaná do 24 hodín.',

    // cards
    'cards.title': 'Aktívne karty',

    // bad csrf and external login error
    'generic.error.badcsrf.text': 'Prosím obnovte stránku a skúste to znova. <br/><br/> Ak problém pretrvá, kontaktujte naše Klientske centrum (0850 00 6500 alebo *6500). <br /> Ospravedlňujeme sa za spôsobené problémy.',
    'generic.error.login.text': 'Prihlásenie sa nepodarilo. Obnovte stránku a skúste sa prihlásiť znova. <br/><br/> Ak problém pretrvá, kontaktujte naše Klientske centrum (0850 00 6500 alebo *6500). <br /> Ospravedlňujeme sa za spôsobené problémy.',

    'cookiebar.text': 'Tieto stránky využívajú cookies. Ďalším prehliadaním stránky súhlasíte s ich využitím. Môžete ich kedykoľvek odmietnuť zmenou nastavenia svojho prehliadača.',
};

const mergedMessages = {...sharedMessages, ...messages};
export default mergedMessages;
 